<template>
  <div>
    <div class="content">
      <div class="prompt">
        <img src="../../assets/images/LTicon.png" alt="">
        <div class="promptText">考核管理</div>
        <div class="querySelect">
          <el-select size="small" :popper-append-to-body="false" clearable class="custom-select" v-model="companyId"
            placeholder="请选择运维单位">
            <el-option v-for="item in ComList" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
          </el-select>
        </div>
        <el-button size="small" type="primary" icon="el-icon-search" @click="gatePlanUnitList">查询</el-button>
      </div>
      <div class="searchContent">
        <div class="contList">
          <div class="statistics">
            <div class="analysis">
              <div class="">
                <div class="echartsTitle">
                  <img src="../../assets/images/titleT.png" alt="">
                  <div class="">计划完成率</div>
                </div>
              </div>
              <!-- <div class="selectData">
                 1111111111
                </div> -->
            </div>
            <div id="electricityEcharts" class="statisticsEcharts">
            </div>
          </div>
          <div class="statistics">
            <div class="analysis">
              <div class="">
                <div class="echartsTitle">
                  <img src="../../assets/images/titleT.png" alt="">
                  <div class="">任务完成率</div>
                </div>
              </div>
              <!-- <div class="selectData">
                  <el-date-picker v-model="value3" type="date" placeholder="日期">
                  </el-date-picker>
                </div> -->
            </div>
            <div id="energyEcharts" class="statisticsEcharts"></div>
          </div>
        </div>
      </div>

      <div class="searchContent">
        <div class="search">
          <div class="statisticsOne">
            <div class="analysis">
              <div class="">
                <div class="echartsTitle">
                  <img src="../../assets/images/titleT.png" alt="">
                  <div class="">服务质量综合评分</div>
                </div>
              </div>
            </div>
            <div id="companyInfoEcharts" class="statisticsEchartsOne">

            </div>
          </div>

        </div>
      </div>
      <div class="prompt">
        <img src="../../assets/images/LTicon.png" alt="">
        <div class="promptText">场站</div>
        <div class="querySelect">
          <el-select size="small" filterable  :popper-append-to-body="false" clearable class="custom-select" v-model="stationId"
            placeholder="请选择场站">
            <el-option v-for="item in stationList" :key="item.id" :label="item.stationName+' '+item.stationNumber"
              :value="item.id"></el-option>
          </el-select>
        </div>
        <el-button size="small" type="primary" icon="el-icon-search" @click="gatePlanStationListSerch">查询</el-button>
      </div>
      <div v-if="!allType" class="searchContent">
        <div class="contList">
          <div class="statistics">
            <div class="analysis">
              <div class="">
                <div class="echartsTitle">
                  <img src="../../assets/images/titleT.png" alt="">
                  <div class="">计划完成率</div>
                </div>
              </div>
              <!-- <div class="selectData">
                 1111111111
                </div> -->
            </div>
            <div  id="electricityEchartsStation" class="statisticsEcharts">
            </div>
          </div>
          <div class="statistics">
            <div class="analysis">
              <div class="">
                <div class="echartsTitle">
                  <img src="../../assets/images/titleT.png" alt="">
                  <div class="">任务完成率</div>
                </div>
              </div>
              <!-- <div class="selectData">
                  <el-date-picker v-model="value3" type="date" placeholder="日期">
                  </el-date-picker>
                </div> -->
            </div>
            <div id="energyEchartsStation" class="statisticsEcharts"></div>
          </div>
        </div>
      </div>
      <!--  -->
      <div v-if=" !allType" class="searchContent">
        <div class="search">
          <div class="statisticsOne">
            <div class="analysis">
              <div class="">
                <div class="echartsTitle">
                  <img src="../../assets/images/titleT.png" alt="">
                  <div class="">服务质量综合评分</div>
                </div>
              </div>
            </div>
            <div id="companyInfoEchartsStation" class="statisticsEchartsOne">

            </div>
          </div>

        </div>
      </div>
      <!-- 底部列表 -->
      <div v-if="allType" class="searchContent">
        <div class="search">
          <div class="listTitle">
            <div class="titleIcon">
              <img src="../../assets/images/titleE.png" alt="">
              <div class="">
                计划完成率
              </div>
            </div>
            <div class="listContent">
              <myecharts v-if="stationPlanNum" id="child" :unitAllNum="stationPlanNum"></myecharts>
              <div class="listTable">
                <div class="tableHeader">
                  <div class="headerText" v-for="(item, index) in stationPlanListBottom" :key="index">{{ item.month
                    }}</div>
                </div>
                <div class="tableContent">
                  <div class="contentHeader">
                    <div class="contentList" v-for="(item, index) in stationPlanListBottom" :key="index">{{
            item.avgCompletionRate }}{{ item.avgCompletionRate == '-' ? '' : '%' }} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if=" allType" class="searchContent">
        <div class="search">
          <div class="listTitle">
            <div class="titleIcon">
              <img src="../../assets/images/titleE.png" alt="">
              <div class="">
                任务完成率
              </div>
            </div>
            <div class="listContent">
              <myecharts v-if="unitAllNum" id='child1' :unitAllNum="unitAllNum"></myecharts>
              <div class="listTable">
                <div class="tableHeader">
                  <!-- <div class="headerText">{{ item.taskMonth}}</div> -->
                  <!-- {{ item }} -->
                  <div class="headerText" v-for="(item, index) in unitStationAllList" :key="index">{{ item.taskMonth }}
                  </div>
                </div>
                <div class="tableContent">
                  <div class="contentHeader">
                    <div class="contentList" v-for="(item, index) in unitStationAllList" :key="index">{{
            item.avgCompletionRate }}{{ item.avgCompletionRate == '-' ? '' : '%' }} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if=" allType" class="searchContent">
        <div class="search">
          <div class="listTitle">
            <div class="titleIcon">
              <img src="../../assets/images/titleE.png" alt="">
              <div class="">
                服务质量综合得分
              </div>
            </div>
            <div class="listContent">
              <myecharts v-if="serviceStationNum" id='child2' :unitAllNum="serviceStationNum"></myecharts>
              <div class="listTable">
                <div class="tableHeader">
                  <div class="headerText" v-for="(item, index) in serviceStationAllList" :key="index">{{ item.taskMonth
                    }}</div>
                </div>
                <div class="tableContent">
                  <div class="contentHeader">
                    <div class="contentList" v-for="(item, index) in serviceStationAllList" :key="index">{{
            item.avgCompletionRate }}{{ item.avgCompletionRate == '-' ? '' : '%' }} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import {
  getOpeComList, operationInspectionTask, getStationList, operationInspectionTaskPlan, operationInspectionTaskStationPlan,
  operationInspectionTaskYear, operationInspectionTaskEntirety, operationInspectionTaskYearEntirety
} from "@/api/testTask.js";
import myecharts from './myecharts.vue';
import * as echarts from "echarts";
export default {
  name: 'Feedback',
  data() {
    return {
      formInline: {
        title: '',//标题
        content: '',//内容
        createBy: '',//创建人
        updateTime: '',//创建时间
      },
      allType:true,
      searchShow:1,
      headerText: [
        '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月',
      ],
      contentList: [
        '98%', '98%', '94%', '98%', '100%', '98%', '89%', '98%', '100%', '98%', '-', '-',
      ],
      companyId: '',
      ComList: [],
      stationList: [],
      stationId: '',
      unitList: [],
      planList: [],
      stationPlanList: [],
      unitStationList: [],
      unitAllNum: 0,
      unitStationAllList: [],
      serviceStationNum: 0,
      serviceStationAllList: [],
      stationPlanNum: 0,
      stationPlanListBottom: [],



    };
  },
  components: { myecharts },

  mounted() {

    this.gatePlanUnitList()
    this.gatePlanStationList()
  },
  created() {
    this.$store.commit('increment', '考核管理')
    this.$store.commit('selectChild', '')
    this.$store.commit('selectChildren', '')
    // this.getList()
    this.onsubmitStation()
    this.onSubmit()

  },

  methods: {
    //获取单位维度的任务完成率
    gatePlanUnitList() {
      operationInspectionTask(this.companyId).then(res => {
        this.unitList=[]
        this.unitList = this.dataSplicing(res.data, 1)
        // console.log(this.unitList);
        this.energyEcharts()
        this.companyInfoEcharts()
      })
      operationInspectionTaskPlan({ company: this.companyId }).then(res => {
        this.planList = this.dataSplicingPlan(res.data, 1)
        console.log("计划完成率", this.planList);
        this.electricityEcharts()

      })
    },
    //数据拼接任务完成率
    dataSplicing(data, type) {
      var productList = ['抢修', '试验', '维修', '巡检'];
      let temp = {
        QX: 0, SY: 0, WX: 0, XJ: 0,
      }
      var result = [['product', ...productList]];
      let mergedData = {};
      if (type == 1) {
        // console.log('112234', Object.keys(data));
        Object.keys(data).forEach((key) => {
          // console.log( data[key]);
          data[key].forEach((item) => {
            // console.log('123',item);
            var stationId = item.stationId;
            // if(item.id)
            // console.log(stationId);
            // console.log('mergedData~~~~~~',mergedData);
            // console.log(mergedData);
            if (!mergedData[stationId]) {
              var bottomName=''
              if(this.companyId){
                bottomName = type == 1 ? item.stationName : item.taskMonthNum
              }else{
                bottomName = type == 1 ? item.companyName : item.taskMonthNum
              }
              mergedData[stationId] = {
                // companyName: item.companyName,
                stationId: item.stationId,
                stationName: bottomName,
                completionRates: JSON.parse(JSON.stringify(temp)),
              };
            }
            mergedData[stationId].completionRates[key] = item.completionRate;

          });
        });
      } else {

        Object.keys(data).forEach((key) => {
          // console.log( data[key]);
          data[key].forEach((item) => {
            var stationId = item.taskMonthNum;
            if (!mergedData[stationId]) {
              mergedData[stationId] = {
                stationId: item.stationId,
                stationName: item.taskMonthNum,
                completionRates: JSON.parse(JSON.stringify(temp)),
              };
            }
            mergedData[stationId].completionRates[key] = item.completionRate;

          });
        });
      }
      console.log(mergedData);
      Object.values(mergedData).forEach((item) => {
        var row = [item.stationName].concat(Object.values(item.completionRates).map(String));
        result.push(row);
      });
      if (type == 2) {
        let arrList = []
        let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        for (var i = 0; i < arr.length; i++) {
          let obj = [0, '0', '0', '0', '0']
          let num = ''
          result.forEach(item => {
            // console.log(item);
            // console.log(arr[i],item);
            if (item[0] == arr[i]) {
              // console.log(item);
              arrList.push(item)
              num = item[0]
            }
          })
          if (num != arr[i]) {
            obj[0] = arr[i]
            arrList.push(obj)
          }
        }
        const monthMap = {
          1: "1月", 2: "2月", 3: "3月", 4: "4月", 5: "5月", 6: "6月", 7: "7月", 8: "8月", 9: "9月", 10: "10月", 11: "11月", 12: "12月"
        };
        arrList.forEach(subArray => {
          subArray[0] = monthMap[subArray[0]] || subArray[0];
        });
        arrList.unshift(result[0]);
        // console.log('~~~~~~~~~~', arrList);
        result = arrList
      }

      console.log(result);
      return result
    },
    //数据拼接计划完成率
    dataSplicingPlan(data, type) {
      // console.log('>>>>>>>111>>>>',data);
      var productList = ['试验', '巡检',];
      let temp = {
        SY: 0, XJ: 0,
      }
      var result = [['product', ...productList]];
      let mergedData = {};
      if (type == 1) {
        // console.log('112234', Object.keys(data));
        Object.keys(data).forEach((key) => {
          // console.log('<<<<~~~~~~~~', data[key]);
          data[key].forEach((item) => {
            // console.log('123',item);
            var stationId = item.stationId;
            if (!mergedData[stationId]) {
              var bottomName=''
              if(this.companyId){
                bottomName = type == 1 ? item.stationName : item.taskMonthNum
              }else{
                bottomName = type == 1 ? item.companyName : item.taskMonthNum
              }
              mergedData[stationId] = {
                // companyName: item.companyName,
                stationId: item.stationId,
                stationName: bottomName,
                completionRates: JSON.parse(JSON.stringify(temp)),
              };
            }
            mergedData[stationId].completionRates[key] = item.completionRate;

          });
        });
      } else {
        Object.keys(data).forEach((key) => {
          console.log( data[key]);
          data[key].forEach((item) => {
            // console.log('***************',item);
            var stationId = item.taskMonthNum;
            if (!mergedData[stationId]) {
              mergedData[stationId] = {
                month:item.taskMonth,
                stationId: item.stationId,
                stationName: item.taskMonthNum,
                completionRates: JSON.parse(JSON.stringify(temp)),
              };
            }
            mergedData[stationId].completionRates[key] = item.completionRate;

          });
        });
      }
    //  console.log(mergedData);
      Object.values(mergedData).forEach((item) => {
        var row = [item.month].concat(Object.values(item.completionRates).map(String));
        result.push(row);
      });
      // console.log('&&&&&&&&&&',result);
      if (type == 2) {
        let arrList = []
        let arr =  [
            '1月', '2月', '3月', '4月', '5月', '6月',
            '7月', '8月', '9月', '10月', '11月', '12月'
          ]
        for (var i = 0; i < arr.length; i++) {
          let obj = [0, '0', '0', '0', '0']
          let num = ''
          result.forEach(item => {
            // console.log(item);
            // console.log(arr[i],item);
            if (item[0] == arr[i]) {
              // console.log(item);
              arrList.push(item)
              num = item[0]
            }
          })
          if (num != arr[i]) {
            obj[0] = arr[i]
            arrList.push(obj)
          }
        }
        const monthMap = {
          1: "1月", 2: "2月", 3: "3月", 4: "4月", 5: "5月", 6: "6月", 7: "7月", 8: "8月", 9: "9月", 10: "10月", 11: "11月", 12: "12月"
        };
        arrList.forEach(subArray => {
          subArray[0] = monthMap[subArray[0]] || subArray[0];
        });
        arrList.unshift(result[0]);
        // console.log('~~~~~~~~~~', arrList);
        result = arrList
      }

      // console.log("^^^^^^^^^^^",result);
      return result
    },
    //搜索-单位
    onSubmit() {
      getOpeComList().then(res => {
        // console.log(res);
        if (res.code == 200) {
          this.ComList = res.data
          // if (!this.companyId) {
          //   this.companyId = this.ComList[0].id
          this.gatePlanUnitList()
          this.companyInfoEcharts()
          // }
        }
      })
    },
    //获取场站下拉框
    onsubmitStation() {
      getStationList().then(res => {
        if (res.code == 200) {
          this.stationList = res.data
          this.gatePlanStationList()

        }
      })
    },
    gatePlanStationListSerch(){
      if(this.stationId){

        this.allType=false
      }else{
        this.allType=true

      }
      this.gatePlanStationList()
    },
    //场站查询按钮
    gatePlanStationList() {
      //获取场站任务完成率
      operationInspectionTaskYear(this.stationId).then(res => {
        if (this.stationId) {
          // this.unitStationList=[]
          this.unitStationList = this.dataSplicing(res.data, 1)

        this.energyEchartsStation()
        this.searchShow=2

        } else {
          let keys = Object.keys(res.data)
          this.unitAllNum = Number(keys[0])
          const allMonths = [
            '一月', '二月', '三月', '四月', '五月', '六月',
            '七月', '八月', '九月', '十月', '十一月', '十二月'
          ];
          this.unitStationAllList = res.data[keys]
          if(this.unitStationAllList.length > 0){

            allMonths.forEach(month => {
              if (!this.unitStationAllList.some(item => item.taskMonth === month)) {
                this.unitStationAllList.push({ taskMonth: month, avgCompletionRate: '-' });
              }
            });
            this.unitStationAllList.sort((a, b) => allMonths.indexOf(a.taskMonth) - allMonths.indexOf(b.taskMonth));
          }else{
            this.unitStationAllList=[]
            this.stationPlanNum=0
            allMonths.forEach(item=>{
              let obj={
                avgCompletionRate:'-',
                taskMonth:item
              }
              this.unitStationAllList.push(obj)
            })


          }
          this.searchShow=1
        }
      })
      //获取底部服务质量综合评分
      operationInspectionTaskYearEntirety().then(res => {
        if (res.code == 200) {
          let keys = Object.keys(res.data)
          this.serviceStationNum = Number(keys[0])
          this.serviceStationAllList = res.data[keys]
          const allMonths = [
            '一月', '二月', '三月', '四月', '五月', '六月',
            '七月', '八月', '九月', '十月', '十一月', '十二月'
          ];
          if( this.serviceStationAllList.length > 0){

            allMonths.forEach(month => {
              if (!this.serviceStationAllList.some(item => item.taskMonth === month)) {
                this.serviceStationAllList.push({ taskMonth: month, avgCompletionRate: '-' });
              }
            });
            this.serviceStationAllList.sort((a, b) => allMonths.indexOf(a.taskMonth) - allMonths.indexOf(b.taskMonth));
          }else{
            this.serviceStationAllList=[]
            this.serviceStationNum=0
            allMonths.forEach(item=>{
              let obj={
                avgCompletionRate:'-',
                taskMonth:item
              }
              this.serviceStationAllList.push(obj)
            })

          }


        }

      })
      
      //获取场站计划完成率
      operationInspectionTaskStationPlan({ station: this.stationId }).then(res => {
        if (this.stationId) {
          this.stationPlanList = this.dataSplicingPlan(res.data, 2)
        this.electricityEchartsStation()
        } else {
          console.log("~~~~~~~~~~~~",res.data.all);
          let keys = Object.keys(res.data)
          this.stationPlanNum = Math.ceil(res.data.allPlanRate)
          this.stationPlanListBottom = res.data.all
          const allMonths = [
            '1月', '2月', '3月', '4月', '5月', '6月',
            '7月', '8月', '9月', '10月', '11月', '12月'
          ];
          if(this.stationPlanListBottom){

            allMonths.forEach(month => {
              if (!this.stationPlanListBottom.some(item => item.month === month)) {
                console.log("1111111");
                this.stationPlanListBottom.push({ taskMonth: month, avgCompletionRate: '-' });
              }
            });
            this.stationPlanListBottom.sort((a, b) => allMonths.indexOf(a.taskMonth) - allMonths.indexOf(b.taskMonth));

          console.log('~~~123123123~~~', this.stationPlanListBottom);

          }else{
            this.stationPlanListBottom=[]
            this.stationPlanNum=0
            allMonths.forEach(item=>{
              let obj={
                avgCompletionRate:'-',
                taskMonth:item
              }
              this.stationPlanListBottom.push(obj)
            })

          }
          
          // console.log('``stationPlanListBottom`', this.stationPlanListBottom);
        }
        // console.log(this.unitStationList);
      })


      if (this.stationId) {
        this.companyInfoEchartsStation()

      }
    },


    //运维单位层次的  计划完成率
    electricityEcharts() {
      var myEcharts = echarts.init(document.getElementById("electricityEcharts"));
      let  typeShow = this.planList.length > 1 ? true:false
      console.log("123123123", typeShow ,this.planList.length );
      var option = {
        // title: typeShow ? null: {
        //   text: '暂无数据',
        //   left: 'center',
        //   top: 'middle',
        //   textStyle: {
        //     color: '#aed6ff', // 设置字体颜色
        //     fontSize: 22     // 设置字体大小
        //   }
        // },

        grid: {
          left: '4%',
          right: '4%',
          width: '100%',
          hight: '100%',
          containLabel: true
        },
        legend: {
          left: 'right',
          top: '0%',
          textStyle: {
            color: "#AED6FF"
          }
        },
        backgroundColor: '#071B42',
        tooltip: {},
        dataset: {
          source: this.planList
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0, // 强制显示所有标签
            //       formatter: function (value) {
            //   return value.split("").join("\n"); // 将标签文字每个字符之间加上换行符
            // },
            formatter: function (value) {
              const maxWidth = 120; // 指定的最大宽度
              let text = value;
              let newText = '';
              for (let i = 0; i < text.length; i++) {
                newText += text[i];
                if (getTextWidth(newText) > maxWidth) {
                  newText = newText.slice(0, -1) + '\n' + text[i];
                }
              }
              return newText;
            },
            textStyle: {
              color: '#aed6ff',// 设置 x 轴标签字体颜色

            }
          }
        },
        yAxis: {
          name: "单位：%",
          // nameLocation: 'end', // 将 y 轴标题显示在轴线开始位置
          // nameGap: 0, // 设置标题与轴线的距离为 30px

          nameTextStyle: {
            color: '#aed6ff',
            // padding: [0, 0, 0, -10]

          },
          splitLine: {
            lineStyle: {
              type: 'dashed',//
              color: "#0b2159"
            },
          },
          axisLabel: {
            textStyle: {
              color: '#aed6ff' // 
            }
          }
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: 'bar',
            barWidth: '10', // 调整柱状图的宽度
            barGap: .8, // 设置柱状图之间的间距
            // barCategoryGap: '400%', // 设置同一系列中柱状图之间的间距
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#2FC9CB'
            }

          },
          {
            type: 'bar',
            barCategoryGap: '30%',
            barWidth: '10', // 调整柱状图的宽度
            barGap: .8, // 设置柱状图之间的间距
            // barCategoryGap: '400%', // 设置同一系列中柱状图之间的间距
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#176AD2'
            }

          },

          // {
          //   type: 'bar',
          //   barWidth: '20%', // 调整柱状图的宽度
          //   itemStyle: {
          //     barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
          //     color: '#E0AB34'
          //   }

          // },

        ],
       
      };
      function getTextWidth(text) {
        const span = document.createElement('span');
        span.style.visibility = 'hidden';
        span.style.whiteSpace = 'nowrap';
        span.style.position = 'absolute';
        span.style.font = '12px sans-serif'; // 设置字体样式
        span.innerText = text;
        document.body.appendChild(span);
        const width = span.offsetWidth;
        document.body.removeChild(span);
        return width;
      }
      
      myEcharts.setOption(option);
    },
    //运维单位层次的 任务完成率
    energyEcharts() {
      var myEcharts = echarts.init(document.getElementById("energyEcharts"));
      let  typeShow = this.unitList.length > 1 ? true:false
      var option = {
        // title: typeShow ? null: {
        //   text: '暂无数据',
        //   left: 'center',
        //   top: 'middle',
        //   textStyle: {
        //     color: '#aed6ff', // 设置字体颜色
        //     fontSize: 22     // 设置字体大小
        //   }
        // },
        grid: {
          left: '5%',
          right: '2%',
          width: '100%',
          hight: '100%',
          containLabel: true
        },
        legend: {
          left: 'right',
          top: '0%',
          textStyle: {
            color: "#AED6FF"
          }
        },
        backgroundColor: '#071B42',
        tooltip: {},
        dataset: {
          source: this.unitList
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0, // 强制显示所有标签
            //       formatter: function (value) {
            //   return value.split("").join("\n"); // 将标签文字每个字符之间加上换行符
            // },
            formatter: function (value) {
              const maxWidth = 120; // 指定的最大宽度
              let text = value;
              let newText = '';
              for (let i = 0; i < text.length; i++) {
                newText += text[i];
                if (getTextWidth(newText) > maxWidth) {
                  newText = newText.slice(0, -1) + '\n' + text[i];
                }
              }
              return newText;
            },
            textStyle: {
              color: '#aed6ff',// 设置 x 轴标签字体颜色

            }
          }
        },
        yAxis: {
          name: "单位：%",
          nameTextStyle: {
            color: '#aed6ff'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',//
              color: "#0b2159"
            },
          },
          axisLabel: {
            textStyle: {
              color: '#aed6ff' // 
            }
          }
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: 'bar',
            barCategoryGap: '30%',
            barWidth: '10', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#176AD2',
              barWidth: 20 // 指定柱状图的宽度
            }
          },
          {
            type: 'bar',
            barWidth: '10', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#2FC9CB',
              barWidth: 20
            }

          },
          {
            type: 'bar',
            barWidth: '10', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#E06034',
              barWidth: 20
            }

          },
          {
            type: 'bar',
            barWidth: '10', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#E0AB34',
              barWidth: 20
            }

          },

        ],

      };
      function getTextWidth(text) {
        const span = document.createElement('span');
        span.style.visibility = 'hidden';
        span.style.whiteSpace = 'nowrap';
        span.style.position = 'absolute';
        span.style.font = '12px sans-serif'; // 设置字体样式
        span.innerText = text;
        document.body.appendChild(span);
        const width = span.offsetWidth;
        document.body.removeChild(span);
        return width;
      }


      myEcharts.setOption(option);
    },
    //运维层次 服务综合质量
    companyInfoEcharts() {
      var data = []
      var list = []
      operationInspectionTaskEntirety({ company: this.companyId }).then(res => {
        if (res.code == 200) {
          res.data.forEach(item => {
            var bottomName=''
            if(this.companyId){
              bottomName = item.stationName
            }else{
              bottomName = item.companyName
            }
            data.push(bottomName)
            list.push(item.avgCompletionRate)
          })
        }
        var myEcharts = echarts.init(document.getElementById("companyInfoEcharts"));
        let  typeShow = data.length > 0 ? true:false
        var option = {
        //   title: typeShow ? null: {
        //   text: '暂无数据',
        //   left: 'center',
        //   top: 'middle',
        //   textStyle: {
        //     color: '#aed6ff', // 设置字体颜色
        //     fontSize: 22     // 设置字体大小
        //   }
        // },
          grid: {
            left: 30,
            right: 10,
            containLabel: true
          },
          tooltip: {},
          legend: {
            data: ["分数"],
            right: '31px',
            top: '16px',
            textStyle: {
              color: "#AED6FF"
            }
          },
          backgroundColor: '#071B42',
          xAxis: {
            data: data,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              textStyle: {
                color: '#aed6ff' // 设置 x 轴标签字体颜色
              }
            }

          },
          yAxis: {
            name: "单位：分",
            nameTextStyle: {
              color: '#aed6ff'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',//
                color: "#0b2159"
              },
            },
            axisLabel: {
              textStyle: {
                color: '#aed6ff' // 
              }
            }
          },
          series: [
            {
              name: "分数",
              type: "bar",
              data: list,
              barCategoryGap: '30%',
              barWidth: '20', // 调整柱状图的宽度
              itemStyle: {
                barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
                color: '#176AD2',
                barWidth: 10
              },
            },
          ],
        };

        myEcharts.setOption(option);
      })

    },
    //场站  计划完成率
    electricityEchartsStation() {
      var myEcharts = echarts.init(document.getElementById("electricityEchartsStation"));

      var option = {
        
        grid: {
          left: '3%',
          right: '2%',
          width: '100%',
          hight: '100%',
          containLabel: true
        },
        legend: {
          left: 'right',
          top: '0%',
          textStyle: {
            color: "#AED6FF"
          }
        },
        backgroundColor: '#071B42',
        tooltip: {},
        dataset: {
          source: this.stationPlanList,
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0, // 强制显示所有标签
            //       formatter: function (value) {
            //   return value.split("").join("\n"); // 将标签文字每个字符之间加上换行符
            // },
            formatter: function (value) {
              const maxWidth = 120; // 指定的最大宽度
              let text = value;
              let newText = '';
              for (let i = 0; i < text.length; i++) {
                newText += text[i];
                if (getTextWidth(newText) > maxWidth) {
                  newText = newText.slice(0, -1) + '\n' + text[i];
                }
              }
              return newText;
            },
            textStyle: {
              color: '#aed6ff',// 设置 x 轴标签字体颜色

            }
          }
        },
        yAxis: {
          name: "单位：%",
          nameTextStyle: {
            color: '#aed6ff'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',//
              color: "#0b2159"
            },
          },
          axisLabel: {
            textStyle: {
              color: '#aed6ff' // 
            }
          }
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: 'bar',
            barWidth: '10', // 调整柱状图的宽度
            barGap: .8, // 设置柱状图之间的间距
            // barCategoryGap: '400%', // 设置同一系列中柱状图之间的间距
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#2FC9CB'
            }

          },
          {
            type: 'bar', barCategoryGap: '30%',
            barWidth: '10', // 调整柱状图的宽度
            barGap: .8, // 设置柱状图之间的间距
            // barCategoryGap: '400%', // 设置同一系列中柱状图之间的间距
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#176AD2'
            }

          },

          // {
          //   type: 'bar',
          //   barWidth: '20%', // 调整柱状图的宽度
          //   itemStyle: {
          //     barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
          //     color: '#E0AB34'
          //   }

          // },

        ]
      };
      function getTextWidth(text) {
        const span = document.createElement('span');
        span.style.visibility = 'hidden';
        span.style.whiteSpace = 'nowrap';
        span.style.position = 'absolute';
        span.style.font = '12px sans-serif'; // 设置字体样式
        span.innerText = text;
        document.body.appendChild(span);
        const width = span.offsetWidth;
        document.body.removeChild(span);
        return width;
      }
      myEcharts.setOption(option);
    },
    //场站 任务完成率
    energyEchartsStation() {
      var myEcharts = echarts.init(document.getElementById("energyEchartsStation"));

      var option = {
        
        grid: {
          left: '5%',
          right: '5%',
          width: '100%',
          hight: '100%',
          containLabel: true
        },
        legend: {
          left: 'right',
          top: '0%',
          textStyle: {
            color: "#AED6FF"
          }
        },
        backgroundColor: '#071B42',
        tooltip: {},
        dataset: {
          source: this.unitStationList
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0, // 强制显示所有标签
            //       formatter: function (value) {
            //   return value.split("").join("\n"); // 将标签文字每个字符之间加上换行符
            // },
            formatter: function (value) {
              const maxWidth = 120; // 指定的最大宽度
              let text = value;
              let newText = '';
              for (let i = 0; i < text.length; i++) {
                newText += text[i];
                if (getTextWidth(newText) > maxWidth) {
                  newText = newText.slice(0, -1) + '\n' + text[i];
                }
              }
              return newText;
            },
            textStyle: {
              color: '#aed6ff' // 设置 x 轴标签字体颜色
            }
          }
        },
        yAxis: {
          name: "单位：%",
          nameTextStyle: {
            color: '#aed6ff',

          },
          splitLine: {
            lineStyle: {
              type: 'dashed',//
              color: "#0b2159"
            },
          },
          axisLabel: {
            textStyle: {
              color: '#aed6ff' // 
            }
          }
        },
        series: [
          {
            type: 'bar',
            barCategoryGap: '30%',
            barWidth: '10', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#176AD2',
              barWidth: 20 // 指定柱状图的宽度
            }

          },
          {
            type: 'bar',
            barWidth: '10', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#2FC9CB',
              barWidth: 20
            }

          },
          {
            type: 'bar',
            barWidth: '10', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#E06034',
              barWidth: 20
            }

          },
          {
            type: 'bar',
            barWidth: '10', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#E0AB34',
              barWidth: 20
            }

          },

        ]
      };
      function getTextWidth(text) {
        const span = document.createElement('span');
        span.style.visibility = 'hidden';
        span.style.whiteSpace = 'nowrap';
        span.style.position = 'absolute';
        span.style.font = '12px sans-serif'; // 设置字体样式
        span.innerText = text;
        document.body.appendChild(span);
        const width = span.offsetWidth;
        document.body.removeChild(span);
        return width;
      }


      myEcharts.setOption(option);
    },
    //场站 服务综合质量
    companyInfoEchartsStation() {
      operationInspectionTaskYearEntirety({ station: this.stationId }).then(res => {
        if (res.code == 200) {
          let data = {}
          let list = res.data
          list.forEach(item => {
            data[item.taskMonth] = item.avgCompletionRate;
          });
          const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
          const result = {};
          months.forEach(month => {
            if (data[month] !== undefined) {
              result[month] = data[month];
            } else {
              result[month] = 0;
            }
          });
          console.log("resoult", result);
          var myEcharts = echarts.init(document.getElementById("companyInfoEchartsStation"));
          var option = {
           
            grid: {
              left: 30,
              right: 10,
              containLabel: true
            },
            tooltip: {},
            legend: {
              data: ["分数"],
              right: '31px',
              top: '16px',
              textStyle: {
                color: "#AED6FF"
              }
            },
            backgroundColor: '#071B42',
            xAxis: {
              data: Object.keys(result),
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {
                textStyle: {
                  color: '#aed6ff' // 设置 x 轴标签字体颜色
                }
              }

            },
            yAxis: {
              name: "单位：分",
              nameTextStyle: {
                color: '#aed6ff'
              },
              splitLine: {
                lineStyle: {
                  type: 'dashed',//
                  color: "#0b2159"
                },
              },
              axisLabel: {
                textStyle: {
                  color: '#aed6ff' // 
                }
              }
            },
            series: [
              {
                name: "分数",
                type: "bar",
                data: Object.values(result),
                barCategoryGap: '30%',
                barWidth: '20', // 调整柱状图的宽度
                itemStyle: {
                  barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
                  color: '#176AD2',
                  barWidth: 10
                },
              },
            ],
          };
          myEcharts.setOption(option);
        }
      })



    },


  },
};
</script>
<style scoped>
/* import */
.content {
  width: 100%;
  background: url(../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

.el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #AED6FF !important;
}

.el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #AED6FF !important;
}

.custom-input ::placeholder {
  color: #AED6FF !important;
}

.custom-button {
  background: #176ad2;
}

.searchContent {
  margin: 21px 25px 16px 26px;
}

.search {
  display: flex;
  justify-content: space-between;
  padding: 14px 26px 13px 20px;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #AED6FF !important;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.demonstration {
  padding-right: 6px;
  font-size: 14px;
  color: #AED6FF !important;
}

.titleIcon {
  display: flex;
  align-items: center;

}

.titleIcon div {
  margin-left: 6px;
}

.listTitle {
  width: 100%;
}

.listContent {
  margin-top: 16px;
  display: flex;
  align-items: center;
  width: 100%;
}

.tableHeader {
  background: #092553;
  display: flex;
  width: 100%;
}

.listTable {
  width: 100%;
}

.headerText,
.contentList {
  width: 14%;
  text-align: center;
  height: 47px;
  line-height: 47px;
  border-right: 1px solid #0d2247;
}

.headerText:last-child {
  border: 0;
}

.tableContent {
  display: flex;
  width: 100%;
  background: #081b3c;

}

.contentHeader {
  display: flex;
  width: 100%;
}

.querySelect {
  margin: 0 10px 0px 30px;
}

.custom-select>>>.el-select-dropdown {
  background: #041C4C !important;
  border: 1px solid #09295b;
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.hover {
  background: #041C4C !important;
  color: #fff;
}

::v-deep .el-select-dropdown__item {
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.selected {
  color: #fff;
}

::v-deep .el-popper[x-placement^=bottom] .popper__arrow::after {
  top: 0;
  border-bottom-color: #041C4C;
}

.el-select {
  width: 100%;
}

.custom-picker {
  width: 100%;
  /* background: #05153A; */
}

.analysis {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid #fff; */

}

.statistics {
  width: 49.5%;
  background: #071B42;
}

.statisticsOne {
  width: 100%;
  background: #071B42;

}

.contList {
  display: flex;
  justify-content: space-between;
  background: #05132E;
}

.statisticsR {
  width: 49%;
}

.echartsTitle {
  background: #071B42;
  width: 100%;
  padding: 15px 0 0 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

}

.statisticsEcharts {
  width: 99%;
  height: 400px;
  /* box-sizing: border-box; */
  /* margin: 0 10px; */
}

.statisticsEchartsOne {
  width: 100%;
  height: 400px;
}
</style>