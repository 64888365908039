<template>
  <div>
    <div :id="id" style="width: 150px; height: 150px;"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: 'CodeMyecharts',
  props: {
    id: String,
    unitAllNum:{
      type:Number,
      default: 0
    }
  },

  data() {
    return {
      
    };
  },


  mounted() {
    this.drawCarbon()
    console.log('~~~~~~',this.unitAllNum); 
  },

  methods: {
    drawCarbon() {
      console.log(this.id);
      let myChart = echarts.init(document.getElementById(this.id))

      // 绘制图表
      var option = {
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              text: `${this.unitAllNum}%`,
              fill: '#0CC076',
              fontSize: 16,
              fontWeight: 'bold'
            }
          }
        ],
        color: ['#0CC076', '#1769CF', ],
        series: [
          {
            type: 'pie',
            radius: ['50%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,

            },
            labelLine: {
              show: true
            },
            itemStyle: {
              borderWidth: 0,
              borderColor: '#fff',
              borderRadius: 0,
              emphasis: {
                scale: false  // 设置为 false，表示在 hover 时大小不改变
              }
            },
            data: [ 
              { value: this.unitAllNum, name: `${this.unitAllNum}%` },
              { value: 100 - this.unitAllNum, name: ` ${100 - this.unitAllNum}%` },
            ]
          }
        ]
      };
      //无法直接使用传过来的值 复制一下
      let num=this.unitAllNum
      myChart.on('click', function (params) {
      // 点击事件中获取对应项的 name，并更新文本元素的内容
      var newText = params.name;
      console.log( num);
      console.log('1111111',params , );
      let color = params.name == num + '%' ?'#0CC076':'#1769CF'
      myChart.setOption({
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              text: newText,
              fill: color,
              fontSize: 16,
              fontWeight: 'bold'
            }
          }
        ]
      });
    });
      myChart.setOption(option);

    }
    
  },
};
</script>

<style scoped>

</style>